import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import MarketBadge from '../components/MarketBadge'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import InvestorReports from '../components/featured/InvestorReports'
// import TotalInvestment from '../components/charts/totalInvestments'

const Investments = (props) => {

  const dataArr2 = [];

  const renderStat = (edge, index) => {
    dataArr2[index] = { date: edge.node.dateFormat.split('-').slice(1).reverse().join('/'), investments: edge.node.investmentCount }
  }

  const renderPlayer = (edge, index) => {

    const isInvestor_2 = () => {
      if (edge.node.investors[1]) {
        return (
          <span> and < Link to={`/investor/${edge.node.investors[1].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[1]}</Link></span>
        )
      }
    }

    const isInvestor_1 = () => {
      if (edge.node.investors[0] && edge.node.investors[0] !== 'Unknown') {
        return (
          <span> with < Link to={`/investor/${edge.node.investors[0].replace(/é/g, "e").toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[0]}</Link></span>
        )
      } else if (edge.node.investors[0] === 'Unknown'){
        return (
          <span>
            
          </span>
        )
      }
    }

    const date = new Date(edge.node.date)

    const isRound = () => {
      if (edge.node.investmentRound){
        return (
          <span> - {edge.node.investmentRound} - </span>
        )
      }
    }

    return (

      <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>

        < Link to={`/business/${edge.node.businessRef.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.businessRef.name}</Link> raised <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</b>{isInvestor_1()} {isInvestor_2()} {isRound()} <MarketBadge market={edge.node.businessRef.what} /> <span style={{ fontSize: 12 }}> - {date.toLocaleDateString()}</span>

      </BS.Card.Body>
      </BS.Card >

    )
  }


  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Investments Ranked By Unicorn Alert</title>
        <meta name="title" content="Investments Ranked By Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>

      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Investments</BS.Breadcrumb.Item>
      </BreadcrumbLite>
        <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>Latest {props.data.allFunding.totalCount} venture capital and private equity deals</h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Most recent investments made in tech businesses (live update)!</h2>
                </BS.Col>
        </BS.Row>


        <BS.Row>
          <BS.Col sm={8} style={{marginTop: -5}}>

            
            {props.data.allFunding.edges.map(renderPlayer)}


          </BS.Col>
          {props.data.allStats.edges.map(renderStat)}
          <BS.Col sm={4}>
            {/* <TotalInvestment d2={dataArr2.reverse()} /> */}
            <InvestorReports />


          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allFunding(sort: {order: DESC, fields: date}, limit: 400) {
    edges {
      node {
        amount
        date
        investors
        currency
        investmentRound
        id
        businessRef {
          ... on Business {
          id
          name
          website
          what
          }
        }
      }
    }
    totalCount
  }
  allInvestor {
    totalCount
  }
  allBusiness {
    totalCount
  }
  allStats (limit: 7, sort: {order: DESC, fields: date}) {
    edges {
      node {
        amountCount
        date
        dateFormat
        investmentCount
      }
    }
  }
}

`
export default Investments